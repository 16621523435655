import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { useAuth } from '../authentication/AuthProvider';
import { 
    Box,
    FormControl,
    Input,
    Card, 
    CardBody, 
    CardFooter,
    Stack,
    Heading,
    ButtonGroup,
    Button,
    InputGroup,
    InputRightElement,
    useToast
  } from '@chakra-ui/react'


const Login = () => {

    const navigate = useNavigate(); // Hook de React Router para navegación
    const { login } = useAuth();
    const toast = useToast()
  
    const [user, setUser] = useState('');
    const handleChangeUser = (e) => setUser(e.target.value)

    const [pass, setPass] = useState('');
    const handleChangePass = (e) => setPass(e.target.value)
    
    const handleLogin = () => {
        if(user === 'admin'){
            if(pass === 'moscuzza1920'){
                login();
                navigate('/stats'); // Redirige a la ruta de Bodega
            }else{
                toast({
                    title: 'Advertencia',
                    description: "Contraseña Incorrecta",
                    status: 'warning',
                    duration: 1000,
                    isClosable: true,
                  })
            }
        }else{
            toast({
                title: 'Error',
                description: "Usuario Incorrecto",
                status: 'error',
                duration: 1000,
                isClosable: true,
              })
        }
    }

    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)

    
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
        handleLogin();
        }
    };

  
  return (
    <Box className="login" background='#f3f3f3' display="flex" alignItems="center" justifyContent="center" w="100vw" h="100vh">
         <Card w="500px"   // Ancho del Card
            minW="500px" // Tamaño mínimo del Card
            h="auto"    // Ajusta la altura automáticamente
            display="flex"
            flexDirection="column" // Asegura que el contenido se apile verticalmente
            >
            <CardBody>
                <Stack>
                    <Box display="flex"
                        justifyContent="center"
                        p={10}>
                        <Heading size='xl'>Log In!</Heading>
                    </Box>

                    <FormControl>
                        <Input variant='outline' placeholder='Usuario' value={user} onChange={handleChangeUser} onKeyPress={handleKeyPress} />
                        <Box mt='20px'>
                            <InputGroup size='md'>
                                <Input
                                    pr='4.5rem'
                                    type={show ? 'text' : 'password'}
                                    placeholder='Enter password'
                                    value={pass}
                                    onChange={handleChangePass}
                                    onKeyPress={handleKeyPress}
                                />
                                <InputRightElement width='4.5rem'>
                                        <Button h='1.75rem' size='sm' onClick={handleClick}>
                                            {show ? 'Hide' : 'Show'}
                                        </Button>
                                </InputRightElement>
                            </InputGroup>
                        </Box>
                    </FormControl>     
                </Stack>
            </CardBody>
            <CardFooter display="flex"
                        justifyContent="center"
                        p={10} >
                <ButtonGroup spacing='2'>
                    <Button variant='solid' bg='#19273a' color='white' type='submit' onClick={handleLogin}>
                        INGRESAR
                    </Button>
                </ButtonGroup>
            </CardFooter>
        </Card>
    </Box>
  );
};


export default Login;
