import React, { useState, useEffect } from 'react';
import Track from './components/track/Track';
import Track2 from './components/track/Track2';
import Login from './components/login/Login';
import { AuthProvider } from './components/authentication/AuthProvider';
import PrivateRoute from './components/authentication/PrivateRoute';
import ConfigRoutes from './config/ConfigRoutes';
import Config from './config/Config';
import ConfigRoutesBack from './config/ConfigRoutesBack';


import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import './App.css';

function App() {
  const [boats, setBoats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(!!document.fullscreenElement);

  const toggleFullScreen = async () => {
    try {
      if (!document.fullscreenElement) {
        await document.documentElement.requestFullscreen();
      } else {
        await document.exitFullscreen();
      }
      setIsFullscreen(!!document.fullscreenElement);
    } catch (err) {
      console.error(`Error attempting to change full-screen mode: ${err.message}`);
    }
  };


  const fetchBoats = async () => {
    try {
      //http://localhost:3080/api/buques
      /*const response = await fetch('http://190.221.35.170:8090/api/vwBqgrf', {
        method: 'GET',
        headers: {
          'x-auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiTW9zY3V6emEgRzQgMjAyNCIsImlhdCI6MTcyNjE1NTA5OH0.nqBNzr_hZ7OtW2GMAp3Fz-ACEkyDy4Y2LYA2v4XTV7c'  
        }
      });*/
      //const response = await fetch('http://localhost:3020/api/external')
      const response = await fetch(Config.baseURL+ConfigRoutesBack.buques)
      const data = await response.json();
      setBoats(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBoats();
    //fetchRRHH();
  }, []);

 
  useEffect(() => {
    // Configurar un intervalo que se ejecuta cada hora (3600000 ms)
    const interval = setInterval(() => {
      fetchBoats();
    }, 21600000); // 3600000 ms = 1 hora

    // Limpiar el intervalo cuando el componente se desmonta
    return () => clearInterval(interval);
  }, []);


//<Route path="/rrhh" element={<PrivateRoute element={<RRHH data1={RRHH3} data2={RRHH2} />} />} />
//<Route path={ConfigRoutes.prod} element={<PrivateRoute element={<Produccion />} />} />

  return (
    <AuthProvider>
      <Router>
        <button className="fullscreen-btn" onClick={toggleFullScreen}>
          {isFullscreen ? '⛶' : '⛶'}
        </button>
        <Routes>
          <Route path={ConfigRoutes.home} element={<Login />} />
          <Route path={ConfigRoutes.stats} element={<PrivateRoute element={<Track2 boats={boats} actualizar={fetchBoats} ocultarBarra={isFullscreen} />} />} />
          <Route path={ConfigRoutes.track} element={<PrivateRoute element={<Track boats={boats} ocultarBarra={isFullscreen} />} />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
